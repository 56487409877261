import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { APIFactoryEndPoints } from "src/app/common/apifactory-end-points";
import { BulkDiscountDto } from "src/app/shared/models/bulkdiscountdto";
import { FBCustomer } from "src/app/shared/models/fbcustomer";
import { GoogleCustomer } from "src/app/shared/models/googlecustomer";
import { GstSaleInvCumBosDto } from "src/app/shared/models/gstsaleinvcumbosdto";
import { LoginCustomerDetails } from "src/app/shared/models/logincustomerdetails";
import { ProductCartDto } from "src/app/shared/models/productcartdto";
import { ProductOrderSummary } from "src/app/shared/models/productordersummary";
import { ProductsWithWebOrderOffers } from "src/app/shared/models/productswithweborderoffers";
import { ViewOfferSetUpDto } from "src/app/shared/models/viewoffersetupdto";
import { ViewWebOrderDeliverySetUpDto } from "src/app/shared/models/viewweborderdeliverysetUpdto";
import { WebOrderGstSetup } from "src/app/shared/models/webordergstsetup";
import { WebOrderMinimumValueSetup } from "src/app/shared/models/weborderminimumvaluesetup";
import { PinDialogService } from "src/app/shared/services/pin-dialog.service";
import { ProductOrderSummaryService } from "src/app/shared/services/product-order-summary.service";
import { ToastService } from "src/app/shared/services/toastr.service";
import { environment } from "src/environments/environment";
import { Product } from "../../../../shared/models/product";
import { ProductService } from "../../../../shared/services/product.service";
import { OfferDialogComponent } from "../offer-dialog/offer-dialog.component";
import * as moment from "moment";
import { WebOrderMinimumDateTimeSetup } from "src/app/shared/models/weborderminimumdatetimesetup";
import { AddressService } from "src/app/shared/services/address.service";
import { UserService } from "src/app/shared/services/user.service";
import { CartDetails } from "src/app/shared/models/cartdetails";
import { PinDialogComponent } from "../pin-dialog/pin-dialog.component";
import { HttpErrorResponse } from "@angular/common/http";
import { LocalStorageService } from "src/app/common/LocalStorageService";
import { getWindow } from "ssr-window";
import { ProductsWithStock } from "src/app/shared/models/products-with-stock";
import { ProductDesignDialogComponent } from "../product-design-dialog/product-design-dialog.component";
import { data, error } from "jquery";
import { OfferDetailsDialogComponent } from "../offer-details-dialog/offer-details-dialog.component";
import { StorePickupDialogComponent } from "../store-pickup-dialog/store-pickup-dialog.component";
import { WebOrderLocationSetup } from "src/app/shared/models/weborderlocationsetup";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { CustomerDeliveryAddress } from "src/app/shared/models/customerdeliveryaddress";

@Component({
  selector: "app-cart-products",
  templateUrl: "./cart-products.component.html",
  styleUrls: ["./cart-products.component.scss"],
})
export class CartProductsComponent implements OnInit {
  @Input() products: Product[];

  // @Output() quantity = new EventEmitter();

  cartProducts: Product[] = [];
  showDataNotFound = true;
  webUrl: string;
  pincode: number;
  productCode: number[];

  public cartList: Product[];
  public totalPrice: number;

  totalNetAmt: number = 0;
  totalSaleAmt: number = 0;
  roundedOffAmount: number = 0;
  netAmountBeforeRoundedOff: number = 0;
  totTaxValue: number = 0;
  totDiscount: number = 0;
  totBasePremiumFlavourPrice: number = 0;
  totPremiumFlavourPrice: number = 0;
  deliveryCharge: number = 0;
  grandTotal: number = 0;
  gstAmt: number = 0;
  cgstAmt: number;
  sgstAmt: number;
  cessAmt: number = 0;
  baseAmt: number;
  netAmt: number;

  productOrderSummary: ProductOrderSummary = new ProductOrderSummary();
  gstSaleInvcumBOS: GstSaleInvCumBosDto = new GstSaleInvCumBosDto();
  productWithOffers: ProductsWithWebOrderOffers[];
  productWithStock: ProductsWithStock[] = [];

  googleId: string;
  googleProvider: string;
  fbId: string;
  fbProvider: string;

  googleDetails: GoogleCustomer;
  fbDetails: FBCustomer;
  pincodeItem: number;

  locId: number;

  webOrderMinimumDateTimeList: WebOrderMinimumDateTimeSetup;

  googleCustomer: GoogleCustomer;
  cartDetails: CartDetails = new CartDetails();
  mtoCartDetails: CartDetails = new CartDetails();

  serverUrl: string;

  offerDetails: ViewOfferSetUpDto[];
  bulkDiscountOffer: BulkDiscountDto;

  orderGstSetup: WebOrderGstSetup;
  minimumOrdeSetup: WebOrderMinimumValueSetup;
  deliverySetup: ViewWebOrderDeliverySetUpDto[] = [];

  productOffer: Product;
  discountOffer: number;
  currentIndex: number;

  bestProducts: Product[] = [];
  options: any;
  loading = false;

  companyCode: number;
  timeSlot2: string[][] = [];
  timeSlotAvl: string[] = [];

  currentUrl: string;
  isShowCheckOut: boolean = false;
  designProducts: Product[] = [];

  deliveryType: string;
  storeLocation: WebOrderLocationSetup;
  deliveryLocation: WebOrderLocationSetup;
  lastDeliveryAddress: CustomerDeliveryAddress;

  // Not Found Message
  messageTitle = "No Products Found in Cart";
  messageDescription = "Please, Add Products to Cart";

  private offerSubscription: Subscription;
  private productOfferSubscription: Subscription;
  private productDiscountSubscription: Subscription;
  private productBulkDiscountSubscription: Subscription;
  private webOrderGstSubcription: Subscription;
  private webOrderMinimumOrderSubcription: Subscription;
  private webOrderDeliverySubcription: Subscription;
  private productSubcription: Subscription;
  private filterproductSubcription: Subscription;
  private webOrderMinimumDateTimeSubcription: Subscription;
  private cartSetUpSubscription: Subscription;
  private webOrderTimeSlotSubcription: Subscription;
  private stockSubscription: Subscription;
  private getDesignSubscription: Subscription;
  private locationSubscription: Subscription;
  private getCustomCakeImageSubscription: Subscription;

  constructor(
    private localStorage: LocalStorageService,
    public dialog: MatDialog,
    private productService: ProductService,
    private pinCodeDialogService: PinDialogService,
    private apiFactory: APIFactoryEndPoints,
    private productOrderSummaryService: ProductOrderSummaryService,
    private navRoute: Router,
    private route: ActivatedRoute,
    private addressService: AddressService,
    private toastService: ToastService,
    private userService: UserService,
    private domSanitizer: DomSanitizer
  ) {
    this.serverUrl = this.apiFactory.getServerUrl;
    // this.serverUrl = "https://1bakeshop.com/mobitrixcartserver/";
  }

  ngOnInit() {
    this.currentUrl = getWindow().location.hostname;
    this.webUrl = environment.cartApiUrl;
    this.companyCode = JSON.parse(this.localStorage.getItem("company_code"));
    this.locId = JSON.parse(this.localStorage.getItem("location_code"));
    this.googleCustomer = JSON.parse(this.localStorage.getItem("google_loggedIn_details"));
    this.deliveryType = JSON.parse(this.localStorage.getItem("delivery_type"));
    this.storeLocation = JSON.parse(this.localStorage.getItem('pickup_location'));
    this.deliveryLocation = JSON.parse(this.localStorage.getItem('delivery_location'));
    this.lastDeliveryAddress = JSON.parse(this.localStorage.getItem('last_delivery_address'));
    console.log(this.storeLocation)
    console.log(this.lastDeliveryAddress)
    this.getAllProducts();
    this.validateNetAmount();
    this.options = {
      dots: false,
      responsive: {
        0: { items: 1, margin: 5 },
        430: { items: 2, margin: 5 },
        550: { items: 3, margin: 5 },
        670: { items: 4, margin: 5 },
      },
      autoplay: true,
      loop: true,
      autoplayTimeout: 3000,
      lazyLoad: true,
    };
  }

  getAllProducts() {
    this.loading = true;

    // Filter products if a product code is provided
    this.cartProducts = this.filterCartProductsByCode();

    const productCodes = this.cartProducts.map(em => em.product_Code);
    console.log(this.cartProducts)

    if (productCodes.length) {
      this.productSubcription = this.productService.getRelevantProductByCartProductCode(this.apiFactory.getFilterRelevantProductsByCartProduct, productCodes, this.currentUrl)
        .subscribe(data => {
          this.bestProducts = data;
          this.getWebOrderMinimumOrderSetUpList();
          this.loading = false;
        },
          error => {
            console.error("Error fetching relevant products:", error);
            this.loading = false;
          }
        );
    } else {
      this.getWebOrderMinimumOrderSetUpList();
      this.loading = false; // Ensure loading is set to false even if no products are found
    }
  }

  filterCartProductsByCode(): any[] {
    const productCode = Number(this.route.snapshot.queryParamMap.get('code'));
    let cartProducts = this.productService.getLocalCartProducts();

    // Perform shelf-life validation before returning the cart products
    cartProducts = this.removeProductsWithLowShelfLife(cartProducts);

    if (!productCode) {
      return cartProducts; // No product code provided, return all filtered products
    }

    // Filter products where any of the conditions match
    return cartProducts.filter(c =>
      [c.product_Code, c.freeByProductCode, c.designForProductCode].includes(productCode)
    );
  }

  removeProductsWithLowShelfLife(products: Product[]): Product[] {
    const minSelfLife = this.deliveryType === 'storePickup'
      ? this.storeLocation?.minSelfLife
      : this.deliveryLocation?.minSelfLife;

    if (!minSelfLife) return products; // No shelf-life restriction

    // Separate valid and invalid products
    const removedProducts = products.filter(p => p.shelf_Life_In_Hours < minSelfLife);
    const updatedProducts = products.filter(p => p.shelf_Life_In_Hours >= minSelfLife);

    if (removedProducts.length > 0) {
      this.productService.removeAllProductsFromCart(removedProducts, updatedProducts);
    }

    return updatedProducts;
  }

  getWebOrderMinimumOrderSetUpList() {
    console.log(this.deliveryType)
    if (this.deliveryType == 'delivery') {
      this.webOrderMinimumOrderSubcription = this.productService.getMinimumOrderList(this.currentUrl).subscribe((data) => {
        this.minimumOrdeSetup = data;
        this.getWebOrderTimeSlotSetUpList();
      });
    } else if (this.deliveryType == 'storePickup') {
      this.minimumOrdeSetup = new WebOrderMinimumValueSetup();
      this.minimumOrdeSetup.orderMinValue = this.storeLocation?.minOrderAmount;
      this.getWebOrderTimeSlotSetUpList();
    } else {
      this.getWebOrderTimeSlotSetUpList();
    }
  }

  getWebOrderTimeSlotSetUpList() {
    const apiUrl = this.deliveryType === 'storePickup' ? this.apiFactory.getTimeSlotDetailsByUrlAndLocId : this.apiFactory.getTimeSlotDetailsByUrl;
    const locId = this.deliveryType === 'storePickup' ? this.storeLocation?.locationCode : null;
    this.webOrderTimeSlotSubcription = this.productService.getTimeSlotList(apiUrl, this.currentUrl, locId)
      .subscribe((data) => {
        data.forEach((element) => {
          let arr: string[] = [];
          arr.push(element.fromTime);
          arr.push(element.toTime);

          this.timeSlot2.push(arr);
        });
        this.getCartProduct();
      });
  }

  getCartProduct() {
    this.cartProducts = this.filterCartProductsByCode();
    this.getCustomCakeImage();
    this.pincode = this.pinCodeDialogService.getPincode();
    this.productCode = this.cartProducts.map(element => element.product_Code);

    if (this.productCode.length > 0) {
      let type = this.deliveryType ? (this.deliveryType === 'storePickup' ? 'Pickup' : 'Delivery') : null;

      if (!type) {
        this.fetchOfferDetails(this.locId || 0, this.productCode);
        return;
      }

      this.offerSubscription = this.productService.getOfferAllowFor(this.apiFactory.getOfferAllowFor, this.currentUrl, type)
        .subscribe((data: string) => {
          if (data === 'Not Available') {
            this.isShowCheckOut = true;
            console.log("No offers available.");
            this.handleNoOfferAvailable();
            return;
          }
          let locId = data === 'allowForWebsite' ? this.googleCustomer?.locationCode || 0 : data === 'allowForLocation' ? this.getLocationId() : 0;
          this.fetchOfferDetails(locId, this.productCode);
        });
    }
    else {
      this.isShowCheckOut = true;
      this.validateNetAmount();
    }
  }

  fetchOfferDetails(locId: number, productCode: number[]) {
    this.offerSubscription = this.productService.getOfferDetails(this.apiFactory.getOfferDetails, locId, productCode, this.currentUrl)
      .subscribe((data: any) => {
        if (data.length > 0) {
          this.productWithOffers = data;

          this.cartProducts.forEach((element) => {
            let arr = this.productWithOffers.filter((offer) => offer.productCode === element.product_Code && !element.freeByProductCode);

            if (arr.length !== 0 && !element.freeByProductCode) {
              element.offerDetails = arr[0].offerDetails;
              element.offers = arr[0].offerDetails.length;
              console.log(element.offers)
              let appliedOffer: ViewOfferSetUpDto = arr[0].offerDetails.find(temp => temp.offerId === element.offerId);
              console.log(appliedOffer)

              if (appliedOffer) {
                if (appliedOffer.minQty > element.orderQuantity ||
                  (appliedOffer.maxQtyPerBill < element.orderQuantity && appliedOffer.schemeType !== "Quantity Bulk Discount")) {

                  if (appliedOffer.schemeType === "Buy 1 get 1 Free" || appliedOffer.schemeType === "Buy X get Y Free") {
                    let freeProductIndex = this.cartProducts.findIndex(e => e.product_Code === element.freeByProductCode);
                    if (freeProductIndex >= 0) {
                      this.cartProducts.splice(freeProductIndex, 1);
                    }
                  }

                  let itemIndex = this.cartProducts.findIndex(e => e.product_Code === element.product_Code);
                  if (itemIndex >= 0) {
                    this.cartProducts[itemIndex].offers = 0;
                    this.cartProducts[itemIndex].discountAmt = 0;
                    this.cartProducts[itemIndex].discountRate = 0;
                    this.cartProducts[itemIndex].promotionOfferId = null;
                    this.cartProducts[itemIndex].offerId = null;
                    this.totDiscount = 0;
                  }
                }
              } else {
                let freeProductIndex = this.cartProducts.findIndex(e => e.freeByProductCode === element.product_Code);
                if (freeProductIndex >= 0) {
                  this.cartProducts.splice(freeProductIndex, 1);
                }

                let itemIndex = this.cartProducts.findIndex(e => e.product_Code === element.product_Code);
                console.log(itemIndex)
                if (itemIndex >= 0) {
                  this.cartProducts[itemIndex].offers = element.offerDetails.length;
                  this.cartProducts[itemIndex].discountAmt = 0;
                  this.cartProducts[itemIndex].discountRate = 0;
                  this.cartProducts[itemIndex].promotionOfferId = null;
                  this.cartProducts[itemIndex].offerId = null;
                  this.totDiscount = 0;
                }
              }
            }
          });
          console.log(this.cartProducts)
          this.syncCartWithLocalStorage();
        } else {
          this.handleNoOfferAvailable();
        }

        this.isShowCheckOut = true;
        this.validateNetAmount();
      });
  }

  private handleNoOfferAvailable() {
    for (let i = this.cartProducts.length - 1; i >= 0; i--) {
      let element = this.cartProducts[i];

      if (element.freeByProductCode) {
        console.log(`Found free product: ${element.product_Code}, associated with main product: ${element.freeByProductCode}`);
        this.cartProducts.splice(i, 1);
        console.log(`Removed free product: ${element.product_Code}`);
      }

      if (element.offerId) {
        element.offerDetails = [];
        element.offers = null;
        element.discountAmt = 0;
        element.discountRate = 0;
        element.promotionOfferId = null;
        element.offerId = null;
        this.totDiscount = 0;
      }
    }

    this.syncCartWithLocalStorage();
  }

  private syncCartWithLocalStorage() {
    const allCartProducts = this.productService.getLocalCartProducts();
    if (this.cartProducts.length !== allCartProducts.length) {
      let allProductList: any = [];
      if (this.googleCustomer != null) {
        allCartProducts.forEach((element) => {
          if (!element.freeByProductCode) {
            element.status = "Not placed";
            element.offerDetails = [];
            element.offers = null;
            element.discountAmt = 0;
            element.discountRate = 0;
            element.promotionOfferId = null;
            element.offerId = null;
            this.totDiscount = 0;
            allProductList.push(element);
          }
        });

        var productList: any = [];
        var mtoList: any = [];

        allProductList.forEach((element) => {
          element.status = "Not placed";
          if (element.cartProductType == "Product") {
            productList.push(element);
          } else {
            mtoList.push(element);
          }
        });

        // this.cartDetails.status = "Not placed";
        // this.cartDetails.logInCustomerEmail = this.googleCustomer.email;
        // this.cartDetails.product = allProductList;

        this.cartDetails.status = "Not placed";
        this.cartDetails.logInCustomerEmail = this.googleCustomer.email;
        this.cartDetails.product = productList;

        this.mtoCartDetails.status = "Not placed";
        this.mtoCartDetails.logInCustomerEmail = this.googleCustomer.email;
        this.mtoCartDetails.product = mtoList;

        console.log(this.cartDetails)
        console.log(this.mtoCartDetails)

        if (productList.length != 0) {
          this.cartSetUpSubscription = this.userService.insertCartSetUp(this.apiFactory.insertProductCart, this.cartDetails)
            .subscribe((data: any) => {
              // Handle insert success or failure if needed
            });
        }


        if (mtoList.length != 0) {
          this.cartSetUpSubscription = this.userService.addProductToCartWithFiles(this.apiFactory.insertMakeToOrderCart, this.mtoCartDetails)
            .subscribe((data: any) => { });
        }
      }

      this.localStorage.setItem("avct_item", JSON.stringify(this.cartProducts));
    }
  }





  getLocationId(): number {
    return JSON.parse(this.localStorage.getItem('location_code')) || 0;
  }

  removeCartProduct(product: Product) {
    this.productService.removeLocalCartProduct(product);
    this.cartProducts = this.productService.getLocalCartProducts();
    this.validateNetAmount();
  }

  getProductWithStock() {
    this.isShowCheckOut = false;
    if (this.locId) {
      this.locationSubscription = this.productService.getCompanyByLocId(this.apiFactory.getLocationByLocationCode, this.locId).subscribe((data: any) => {
        if (data && data.status == 'Active') {
          if (this.deliveryType == 'delivery') {
            this.locationSubscription = this.productService.checkAllowStockByLocAndUrl(this.apiFactory.checkAllowStockByLocAndUrl, this.locId, this.currentUrl).subscribe((data: any) => {
              if (data) {
                if (this.productCode.length > 0) {
                  this.stockSubscription = this.productService.getStockDetails(this.apiFactory.getStockDetails, this.productCode, this.locId).subscribe((data: any) => {
                    this.productWithStock = data;
                    this.cartProducts.forEach((element, index) => {
                      if (!this.isShowCheckOut) {
                        var arr = this.productWithStock.filter((p) => p.product_Code == element.product_Code);
                        if (arr.length != 0) {
                          element.systemStock = arr[0].systemStock;
                        }
                        if (element.cartProductType == 'Product' && element.orderQuantity > element.systemStock) {
                          this.toastService.showErrorToast(element.product_Name + " is out of stock, stock available " + element.systemStock);
                          this.isShowCheckOut = true;
                          return;
                        }
                      }
                    });
                    if (!this.isShowCheckOut)
                      this.getWebOrderMinMaxTimeSetUp();
                  },
                    (error: HttpErrorResponse) => {
                      this.isShowCheckOut = true;
                      this.toastService.showErrorToast("Something wrong..");
                    });
                } else {
                  if (!this.isShowCheckOut)
                    this.getWebOrderMinMaxTimeSetUp();
                }
              } else {
                this.isShowCheckOut = true;
                this.getWebOrderMinMaxTimeSetUp();
              }
            },
              (error: HttpErrorResponse) => {
                this.isShowCheckOut = true;
                this.toastService.showErrorToast("Something wrong..");
              })
          } else if (this.deliveryType == 'storePickup') {
            this.isShowCheckOut = true;
            this.getWebOrderMinMaxTimeSetUp();
          } else {
            this.openStorePickupDialog();
          }
        } else {
          this.isShowCheckOut = true;
          this.toastService.showErrorToast("Location can't place order");
        }
      },
        (error: HttpErrorResponse) => {
          this.isShowCheckOut = true;
          this.toastService.showErrorToast("Something wrong..");
        });
    }
    else {
      this.openStorePickupDialog();
    }
  }

  openStorePickupDialog() {
    const storeDialogRef = this.dialog.open(StorePickupDialogComponent, {
      width: '300px',
      autoFocus: false,
      panelClass: 'store-dialog-container'
    });

    storeDialogRef.afterClosed().subscribe(result => {
      console.log(result)
      this.isShowCheckOut = true;
      if (result) {
        if (result === 'storePickup') {
          this.locId = JSON.parse(this.localStorage.getItem("location_code"));
        } else if (result === 'delivery') {
          this.locId = JSON.parse(this.localStorage.getItem("location_code"));
          window.location.reload();
          // this.openPinDialog();
        }
      }
    });
  }

  openPinDialog() {
    const dialogRef = this.dialog.open(PinDialogComponent, {
      width: "300px",
      data: "",
      panelClass: "pin-dialog-container",
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result)
      if (result?.result == true) {
        this.locId = JSON.parse(this.localStorage.getItem("location_code"));
        // this.isShowCheckOut = true;
        window.location.reload();
      }
    });
  }

  increaseQuantity(item: any) {
    item.orderQuantity++;
    this.updateItem(item);
  }

  decreaseQuantity(item: any) {
    if (item.orderQuantity > 1) {
      item.orderQuantity--;
      this.updateItem(item);
    }
  }


  updateItem(product: Product) {
    if (product.orderQuantity <= 0) {
      product.orderQuantity = 1;
    }

    // Ensure orderQuantity is at least 1 and round it properly
    product.orderQuantity = Math.max(1, Math.floor(product.orderQuantity));

    const cartProducts: Product[] = this.cartProducts;
    var productList: any = [];

    if (cartProducts.some((temp) => temp.product_Code == product.product_Code && temp.cartProductType == "Product")) {
      let itemIndex = cartProducts.findIndex((item) => item.product_Code == product.product_Code);
      if (itemIndex >= 0)
        cartProducts[itemIndex] = product;
    }
    if (cartProducts.some((temp) => temp.designForProductCode == product.product_Code && temp.cartProductType == "Product")) {
      let designIndex = cartProducts.findIndex(e => e.designForProductCode === product.product_Code);
      if (designIndex >= 0)
        cartProducts[designIndex].orderQuantity = product.orderQuantity;
    }
    if (product.offerId) {
      this.appliedOfferCheck(product);
    }


    if (this.googleCustomer != null) {
      cartProducts.forEach((element) => {
        if (element.cartProductType == "Product") {
          element.status = "Not placed";
          productList.push(element);
        }
      });

      this.cartDetails.status = "Not placed";
      this.cartDetails.logInCustomerEmail = this.googleCustomer.email;
      this.cartDetails.product = productList;
      this.cartSetUpSubscription = this.userService.insertCartSetUp(this.apiFactory.insertProductCart, this.cartDetails)
        .subscribe((data: any) => { });
    }
    this.localStorage.setItem("avct_item", JSON.stringify(cartProducts));
    this.validateNetAmount();
  }

  appliedOfferCheck(product: Product) {
    let appliedOffer: ViewOfferSetUpDto = product.offerDetails.find(temp => temp.offerId == product.offerId);
    if (appliedOffer) {
      if (appliedOffer.minQty <= product.orderQuantity && appliedOffer.maxQtyPerBill >= product.orderQuantity) {
        if (appliedOffer.schemeType == "Buy 1 get 1 Free" || appliedOffer.schemeType == "Buy X get Y Free") {
          if (this.cartProducts.some((temp) => temp.freeByProductCode == product.product_Code && temp.cartProductType == "Product")) {
            let offerIndex = this.cartProducts.findIndex(e => e.freeByProductCode === product.product_Code);
            if (offerIndex >= 0)
              this.cartProducts[offerIndex].orderQuantity = product.orderQuantity;
          }
        }
      }
      else {
        if (appliedOffer.schemeType == "Quantity Bulk Discount" && appliedOffer.minQty <= product.orderQuantity) {

        } else {
          this.toastService.clearAllToast();
          this.toastService.showErrorToast("Offer Qty Exceeded");
          let offerIndex = this.cartProducts.findIndex(e => e.freeByProductCode === product.product_Code);
          if (offerIndex >= 0)
            this.cartProducts.splice(offerIndex, 1);

          let itemIndex = this.cartProducts.findIndex(e => e.product_Code === product.product_Code);
          if (itemIndex >= 0) {
            this.cartProducts[itemIndex].offers = product.offerDetails.length;
            this.cartProducts[itemIndex].discountAmt = 0;
            this.cartProducts[itemIndex].discountRate = 0;
            this.cartProducts[itemIndex].promotionOfferId = null;
            this.cartProducts[itemIndex].offerId = null;
            this.totDiscount = 0;
          }
        }
      }
    }
  }

  validateNetAmount() {
    this.totalNetAmt = 0;
    this.totalSaleAmt = 0;
    this.totDiscount = 0;
    this.gstAmt = 0;
    this.cessAmt = 0;
    this.totBasePremiumFlavourPrice = 0;
    this.totPremiumFlavourPrice = 0;
    this.roundedOffAmount = 0;
    this.netAmountBeforeRoundedOff = 0;

    this.cartProducts.forEach((element) => {
      if (element.orderQuantityKgs == null) {
        this.totalSaleAmt += Number((element.orderQuantity * element.sale_Price).toFixed(2));
        if (element.discountAmt) {
          this.totDiscount += Number((element?.discountAmt * element.orderQuantity).toFixed(2));
        }
        if (element.taxAmount) {
          this.gstAmt += Number((element.orderQuantity * element?.taxAmount).toFixed(2));
        }
        if (element.cessAmount) {
          this.cessAmt += Number((element.orderQuantity * element?.cessAmount).toFixed(2));
        }
        this.totTaxValue = (this.totalSaleAmt + this.totBasePremiumFlavourPrice) - this.totDiscount;
        // this.totalNetAmt = element.orderQuantity * element.net_Sale_Price;
        this.totalNetAmt = this.totTaxValue + this.gstAmt + this.cessAmt;
      } else {
        this.totalSaleAmt += Number((element.orderQuantityKgs * element.sale_Price).toFixed(2));
        this.totBasePremiumFlavourPrice += Number((element.basePremiumFlavourPrice * element.orderQuantityKgs).toFixed(2));
        this.totPremiumFlavourPrice += Number((element.premiumFlavourPrice * element.orderQuantityKgs).toFixed(2));
        if (element.discountAmt) {
          this.totDiscount += Number((element?.discountAmt * element.orderQuantityKgs).toFixed(2));
        }
        if (element.taxAmount) {
          this.gstAmt += Number((element.orderQuantityKgs * element?.taxAmount).toFixed(2));
        }
        if (element.cessAmount) {
          this.cessAmt += Number((element.orderQuantityKgs * element?.cessAmount).toFixed(2));
        }
        this.totTaxValue = (this.totalSaleAmt + this.totBasePremiumFlavourPrice) - this.totDiscount;
        // this.totalNetAmt = element.orderQuantityKgs * element.net_Sale_Price + element.orderQuantityKgs * element.premiumFlavourPrice;
        this.totalNetAmt = this.totTaxValue + this.gstAmt + this.cessAmt;
      }
    });
    this.roundedOffAmount = Math.round(this.totalNetAmt) - this.totalNetAmt;
    this.netAmountBeforeRoundedOff = this.totalNetAmt;
    this.totalNetAmt = Math.round(this.totalNetAmt);
    this.grandTotal = this.totalNetAmt + this.deliveryCharge;
  }

  offerObj: any;
  openDialog(offers: ViewOfferSetUpDto[], index: number) {
    this.currentIndex = index;
    let appliedOfferId: string = this.cartProducts[this.currentIndex].offerId;
    const dialogRef = this.dialog.open(OfferDialogComponent, {
      width: "500px",
      height: "300px",
      data: { offers, appliedOfferId },
    });

    dialogRef.afterClosed().subscribe((offerObj) => {
      console.log(offerObj);
      if (offerObj && offerObj.offerId != appliedOfferId) {
        this.offerObj = offerObj;
        //conditions
        var orderQuantity;
        var freeByProductCode;
        var cartProductType;
        var productArr = this.cartProducts.filter((element) => {
          if (element.offerDetails != undefined && element.offerDetails.length != 0) {
            if (element.offerDetails.findIndex((offerElement) => offerElement.offerId == offerObj.offerId) != -1) {
              return true;
            }
          }
        });
        if (productArr.length != 0) {
          orderQuantity = productArr[0].orderQuantity;
          freeByProductCode = productArr[0].product_Code;
          cartProductType = productArr[0].cartProductType;
        }

        let offerIndex = this.cartProducts.findIndex(e => e.freeByProductCode === productArr[0].product_Code);
        if (offerIndex >= 0)
          this.cartProducts.splice(offerIndex, 1);

        if (offerObj.schemeType == "Buy 1 get 1 Free" || offerObj.schemeType == "Buy X get Y Free") {
          this.productOfferSubscription = this.productService.getProductOfferDetails(this.apiFactory.productOfferDetails, offerObj.offerId, orderQuantity)
            .subscribe(
              (data) => {
                // offer processing
                this.productOffer = data;
                //set order qty as product QTY IN CARTsy
                this.productOffer.product_Name = this.productOffer.product_Name.concat("(").concat(offerObj.schemeType).concat(")");
                this.productOffer.orderQuantity = orderQuantity;
                this.productOffer.net_Sale_Price = offerObj.productPriceForCustomer;
                this.productOffer.sale_Price = offerObj.productPriceForCustomer;
                this.productOffer.discountAmt = 0;
                this.productOffer.discountRate = 0;
                this.productOffer.freeProductNetSalePrice = offerObj.netSalePrice;
                this.productOffer.freeByProductCode = freeByProductCode;
                this.productOffer.cartProductType = cartProductType;
                this.productOffer.product_Type = this.productOffer.product_Type;
                this.productOffer.product_Unit = this.productOffer.product_Unit;
                this.productOffer.food_Type = this.productOffer.food_Type;
                this.productOffer.hsn_Code = this.productOffer.hsn_Code;
                this.productOffer.subGroupCode = this.productOffer.subGroupCode;
                this.productOffer.defaultDate = productArr[0].defaultDate;
                this.productOffer.deliveryDate = productArr[0].deliveryDate;
                this.productOffer.deliveryTime = productArr[0].deliveryTime;
                this.productOffer.subGroupCode = this.productOffer.subGroupCode;
                this.cartProducts.splice(this.currentIndex + 1, 0, this.productOffer);
                // this.cartProducts[this.currentIndex].offers = 0;

                // main product tax and net amount calculations
                productArr[0].discountAmt = 0;
                productArr[0].discountRate = 0;
                this.totDiscount = 0;
                var taxableAmt = productArr[0].sale_Price - (productArr[0].sale_Price * 0) / 100;
                taxableAmt = taxableAmt + productArr[0].basePremiumFlavourPrice;
                this.totTaxValue = taxableAmt;
                var saleAmt = productArr[0].sale_Price;
                this.baseAmt = saleAmt;
                var cess = productArr[0].cess.cessRate;
                this.cessAmt = (cess / 100) * taxableAmt;
                var cgst = (productArr[0].tax.taxRate / 2 / 100) * taxableAmt;
                this.gstAmt = cgst;
                this.cgstAmt = cgst;
                var sgst = (productArr[0].tax.taxRate / 2 / 100) * taxableAmt;
                this.sgstAmt = sgst;
                var tot = cgst + sgst;
                productArr[0].promotionOfferId = offerObj.offerId;
                productArr[0].offerId = offerObj.offerId;
                productArr[0].taxAmount = tot; //Tax Amount
                productArr[0].cgstAmt = cgst;
                productArr[0].sgstAmt = sgst;
                productArr[0].cessAmount = this.cessAmt;
                productArr[0].net_Sale_Price = taxableAmt + tot + this.cessAmt;
                var net = productArr[0].net_Sale_Price;
                this.netAmt = net;
                this.productService.addToCartOffer(this.cartProducts);
                this.validateNetAmount();
              },
              (err) => {
                if (err.error) {
                  this.toastService.showErrorToast(err.error.message);
                }
              }
            );
        } else {
          if (offerObj.schemeType == "Buy x get x percent off") {
            this.productDiscountSubscription = this.productService.getProductDiscountDetails(this.apiFactory.productDiscountDetails, offerObj.offerId, orderQuantity)
              .subscribe(
                (data) => {
                  this.discountOffer = data;
                  productArr[0].discountAmt = (this.discountOffer / 100) * productArr[0].sale_Price;
                  productArr[0].discountRate = this.discountOffer;
                  // this.cartProducts[this.currentIndex].offers = 0;
                  this.totDiscount = productArr[0].discountAmt;
                  // productArr[0].net_Sale_Price=productArr[0].net_Sale_Price-productArr[0].net_Sale_Price*this.discountOffer/100;
                  var taxableAmt = productArr[0].sale_Price - (productArr[0].sale_Price * this.discountOffer) / 100;
                  taxableAmt = taxableAmt + productArr[0].basePremiumFlavourPrice;
                  this.totTaxValue = taxableAmt;
                  var saleAmt = productArr[0].sale_Price;
                  this.baseAmt = saleAmt;
                  var cess = productArr[0].cess.cessRate;
                  this.cessAmt = (cess / 100) * taxableAmt;
                  var cgst = (productArr[0].tax.taxRate / 2 / 100) * taxableAmt;
                  this.gstAmt = cgst;
                  this.cgstAmt = cgst;
                  var sgst = (productArr[0].tax.taxRate / 2 / 100) * taxableAmt;
                  this.sgstAmt = sgst;
                  var tot = cgst + sgst;
                  productArr[0].promotionOfferId = offerObj.offerId;
                  productArr[0].offerId = offerObj.offerId;
                  productArr[0].taxAmount = tot; //Tax Amount
                  productArr[0].cgstAmt = cgst;
                  productArr[0].sgstAmt = sgst;
                  productArr[0].cessAmount = this.cessAmt;
                  productArr[0].net_Sale_Price = taxableAmt + tot + this.cessAmt;
                  var net = productArr[0].net_Sale_Price;
                  this.netAmt = net;
                  this.productService.addToCartOffer(this.cartProducts);
                  this.validateNetAmount();
                },
                (err) => {
                  if (err.error) {
                    this.toastService.showErrorToast(err.error.message);
                  }
                }
              );
          } else {
            this.productBulkDiscountSubscription = this.productService.getProductBulkDiscountDetails(this.apiFactory.productBulkOfferDetails, offerObj.offerId)
              .subscribe((data) => {
                if (data.minQty <= productArr[0].orderQuantity) {
                  this.bulkDiscountOffer = data;
                  // this.cartProducts[this.currentIndex].offers = 0;
                  productArr[0].discountAmt = (this.bulkDiscountOffer.discount / 100) * productArr[0].sale_Price;
                  productArr[0].discountRate = this.bulkDiscountOffer.discount;
                  this.totDiscount = productArr[0].discountAmt; //discount:offer discount
                  // this.quantity.emit(this.bulkDiscountOffer.minQty);
                  // productArr[0].net_Sale_Price = productArr[0].net_Sale_Price - productArr[0].net_Sale_Price * this.bulkDiscountOffer.discount / 100;
                  var taxableAmt = productArr[0].sale_Price - (productArr[0].sale_Price * this.bulkDiscountOffer.discount) / 100;
                  taxableAmt = taxableAmt + productArr[0].basePremiumFlavourPrice;
                  this.totTaxValue = taxableAmt;
                  var saleAmt = productArr[0].sale_Price;
                  this.baseAmt = saleAmt;
                  var cess = productArr[0].cess.cessRate;
                  this.cessAmt = (cess / 100) * taxableAmt;
                  var cgst = (productArr[0].tax.taxRate / 2 / 100) * taxableAmt;
                  this.gstAmt = cgst;
                  this.cgstAmt = cgst;
                  var sgst = (productArr[0].tax.taxRate / 2 / 100) * taxableAmt;
                  this.sgstAmt = sgst;
                  var tot = cgst + sgst;
                  productArr[0].promotionOfferId = offerObj.offerId;
                  productArr[0].offerId = offerObj.offerId;
                  productArr[0].taxAmount = tot; //Tax Amount
                  productArr[0].cgstAmt = cgst;
                  productArr[0].sgstAmt = sgst;
                  productArr[0].cessAmount = this.cessAmt;
                  productArr[0].net_Sale_Price = taxableAmt + tot + this.cessAmt;
                  var net = productArr[0].net_Sale_Price;
                  this.netAmt = net;
                  this.productService.addToCartOffer(this.cartProducts);
                  this.validateNetAmount();
                } else {
                  this.toastService.showErrorToast("Offer Qty Exceeded");
                }
              });
          }
        }
      } else {
        // let offerIndex = this.cartProducts.findIndex(e => e.freeByProductCode === this.cartProducts[this.currentIndex].product_Code);
        // if (offerIndex >= 0)
        //   this.cartProducts.splice(offerIndex, 1);

        // let itemIndex = this.cartProducts.findIndex(e => e.product_Code === this.cartProducts[this.currentIndex].product_Code);
        // if (itemIndex >= 0) {
        //   this.cartProducts[itemIndex].offers = this.cartProducts[this.currentIndex].offerDetails.length;
        //   this.cartProducts[itemIndex].discountAmt = 0;
        //   this.cartProducts[itemIndex].discountRate = 0;
        //   this.cartProducts[itemIndex].promotionOfferId = null;
        //   this.cartProducts[itemIndex].offerId = null;
        //   this.totDiscount = 0;
        // }
        // var productList: any = [];

        // if (this.googleCustomer != null) {
        //   this.cartProducts.forEach((element) => {
        //     if (element.cartProductType == "Product") {
        //       element.status = "Not placed";
        //       productList.push(element);
        //     }
        //   });

        //   this.cartDetails.status = "Not placed";
        //   this.cartDetails.logInCustomerEmail = this.googleCustomer.email;
        //   this.cartDetails.product = productList;
        //   this.cartSetUpSubscription = this.userService.insertCartSetUp(this.apiFactory.insertProductCart, this.cartDetails)
        //     .subscribe((data: any) => { });
        // }
        // this.localStorage.setItem("avct_item", JSON.stringify(this.cartProducts));
        // this.validateNetAmount();
      }
    });
  }

  getWebOrderGstSetUpList() {
    this.webOrderGstSubcription = this.productService.getGstList(this.currentUrl).subscribe((data) => {
      this.orderGstSetup = data;
    });
  }

  // getWebOrderDeliverySetUpList() {
  //   this.webOrderDeliverySubcription = this.productService.getDeliveryList(this.currentUrl).subscribe((data) => {
  //     this.deliverySetup = data;
  //     this.grandTotal = this.totalNetAmt + this.deliveryCharge;
  //   });
  // }

  getDesignBySubGroupAndUrl(product: Product) {
    // let design: Product = this.cartProducts.filter(e => e.product_Code == product.designForProductCode)[0];
    let design: Product = this.cartProducts.filter(e => e.product_Code == product.product_Code)[0];
    if (this.getDesignSubscription)
      return;

    this.getDesignSubscription = this.productService.getWebOrderDesigns(this.apiFactory.getWebOrderDesignBySubgroupAndUrl, design.productSubGroup.refNo, this.currentUrl).subscribe((data) => {
      this.getDesignSubscription = null;
      if (data.length > 0) {
        this.designProducts = data;
        this.designProducts.map(e => e.is_Default = false);
        // let designIndex = this.designProducts.findIndex(e => e.product_Code === product.product_Code);
        let designIndex = this.designProducts.findIndex(e => e.product_Code === Number(product.designProductCode));
        if (designIndex >= 0)
          this.designProducts[designIndex].is_Default = true;

        this.openDesignDialog(design, this.designProducts);
      } else {
        // this.productService.addToCart(design);
        // this.toastrService.showErrorToast('No design available');
      }
    });
  }

  openDesignDialog(product: Product, designProducts: Product[]) {
    // let productList: Product[] = [];
    // productList.push(product);
    document.body.classList.add('dialog-open');
    const dialogRef = this.dialog.open(ProductDesignDialogComponent, {
      width: "430px",
      height: "410px",
      maxWidth: "90vw",
      data: designProducts,
      panelClass: "custom-dialog-container",
    });

    dialogRef.afterClosed().subscribe((result) => {
      document.body.classList.remove('dialog-open');
      if (result) {
        if (result?.data) {
          let design: Product = result?.data;
          design.designForProductCode = product.product_Code;
          design.cartProductType = "Product";
          this.productService.addToCartDesign(product, design);
          this.cartProducts = this.filterCartProductsByCode();
          // if (this.offerObj) {
          //   this.appliedOfferCheck(product);
          // }
          this.validateNetAmount();
        } else if (result?.noDesign) {
          this.productService.removeDesignLocalCartProduct(product);
          this.cartProducts = this.filterCartProductsByCode();
          // if (this.offerObj) {
          //   this.appliedOfferCheck(product);
          // }
          this.validateNetAmount();
        }
      }
    });
  }

  goShopping() {
    this.navRoute.navigate(["/products"]);
  }

  addFavourite(product: Product) {
    product.cartProductType = "Product";
    this.productService.addFavouriteProduct(product);
  }

  openDeliveryTypeDialog(product: Product) {
    let deliveryType = JSON.parse(this.localStorage.getItem('delivery_type'));
    let locId = JSON.parse(this.localStorage.getItem('location_code'));
    console.log('Delivery Type:', deliveryType, 'Location ID:', locId);
    if (!deliveryType || !locId) {
      const dialogRef = this.dialog.open(StorePickupDialogComponent, {
        width: '300px',
        autoFocus: false,
        panelClass: 'store-dialog-container'
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
        if (result) {
          if (result === 'storePickup') {
            this.setStorePickup(product);
          } else if (result === 'delivery') {
            this.addToCart(product);
          }
        }
      });
    } else {
      if (deliveryType === 'storePickup') {
        this.setStorePickup(product);
      } else if (deliveryType === 'delivery') {
        this.addToCart(product);
      }
    }
  }

  setStorePickup(product: Product) {
    let selfLife = JSON.parse(this.localStorage.getItem('min_selflife'));
    if (product.shelf_Life_In_Hours >= selfLife) {
      product.cartProductType = 'Product';
      this.productService.addToCart(product);
    } else {
      this.toastService.showErrorToast('Can not added to cart');
    }
  }

  addToCart(product: Product) {
    this.pincode = JSON.parse(this.localStorage.getItem("pincode_item"));
    if (this.pincode == null) {
      const dialogRef = this.dialog.open(PinDialogComponent, {
        width: "300px",
        data: product,
        panelClass: "pin-dialog-container",
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result?.result == true) {
          // this.getGroupList();
          let selfLife = JSON.parse(this.localStorage.getItem("min_selflife"));
          if (product.shelf_Life_In_Hours >= selfLife) {
            product.cartProductType = "Product";
            this.productService.addToCart(product);
          } else {
            this.toastService.showErrorToast("Can not added to cart");
          }
        }
      });
    } else {
      let selfLife = JSON.parse(this.localStorage.getItem("min_selflife"));
      if (product.shelf_Life_In_Hours >= selfLife) {
        product.cartProductType = "Product";
        this.productService.addToCart(product);
      } else {
        this.toastService.showErrorToast("Can not added to cart");
      }
    }
    setTimeout(() => {
      this.getCartProduct();
    }, 500);
  }

  editMto(id: number) {
    var mto: string = "editMto";
    this.navRoute.navigate(["/make-product", id,
      // {mto}
    ]);
  }

  viewOfferDetail(offers: ViewOfferSetUpDto[]) {
    let dialogRef = this.dialog.open(OfferDetailsDialogComponent, {
      width: '550px',
      minHeight: '18%',
      maxWidth: '90%',
      maxHeight: '70%',
      autoFocus: false,
      data: { offerData: offers }
    });

    dialogRef.afterClosed().subscribe(res => {

    })
  }

  getWebOrderMinMaxTimeSetUp() {
    console.log(this.deliveryType)
    if (this.deliveryType == 'delivery') {
      this.webOrderMinimumDateTimeSubcription = this.addressService.getWebOrderMinimumDateTimeDetails(this.currentUrl).subscribe((data) => {
        this.webOrderMinimumDateTimeList = data;
        this.getSummary();
      },
        (error: HttpErrorResponse) => {
          this.isShowCheckOut = true;
        }
      );
    } else if (this.deliveryType == 'storePickup') {
      this.webOrderMinimumDateTimeList = new WebOrderMinimumDateTimeSetup();
      this.webOrderMinimumDateTimeList.deliveryTime = this.storeLocation?.minOrderTime;
      this.webOrderMinimumDateTimeList.maxDeliveryTime = this.storeLocation?.maxOrderTime;
      this.getSummary();
    } else {
      this.isShowCheckOut = true;
    }
  }

  getCustomCakeImage() {
    this.cartProducts.forEach((element) => {
      if (element.subMtoType == 'Custom Cakes') {
        this.getCustomCakeImageSubscription = this.productService.downloadImage(this.apiFactory.getServerUrl, 'cartcustomizedproduct', element.product_Picture).subscribe((data) => {
          if (data.toString().includes("ArrayBuffer") == true) {
            let file = new Blob([data], { type: 'image/jpeg' });
            let fl1 = URL.createObjectURL(file);
            let fl2: SafeUrl = this.domSanitizer.bypassSecurityTrustUrl(fl1);
            element.image = fl2;
          }
        });
      }
    });
  }

  getSummary() {
    this.pincodeItem = JSON.parse(this.localStorage.getItem("pincode_item"));
    let currDate = moment().format("YYYY-MM-DD");

    let orderDuration = moment().add(
      Number(this.webOrderMinimumDateTimeList?.deliveryTime?.split(":")[0]), "hours"
    ).add(
      Number(this.webOrderMinimumDateTimeList?.deliveryTime?.split(":")[1]), "minutes"
    );

    let maxOrderDuration = moment().add(
      Number(this.webOrderMinimumDateTimeList?.maxDeliveryTime?.split(":")[0]), "hours"
    ).add(
      Number(this.webOrderMinimumDateTimeList?.maxDeliveryTime?.split(":")[1]), "minutes"
    );

    console.log(this.cartProducts);

    this.cartProducts.forEach((element) => {
      if (element.cartProductType === "Make To Order") {
        if (element.freeByProductCode) {
          element.rate = element.rate;
          element.totalAmount = element.subTotal;
        }

        let minOrderDate = moment().add(element.minimum_Order_Time, "days");
        let existingDeliveryDate = moment(element.deliveryDate);
        let greaterDate = orderDuration.isAfter(minOrderDate) ? orderDuration : minOrderDate;
        let orderTimeMinutes = orderDuration.hours() * 60 + orderDuration.minutes();

        // If the existing delivery date is before the greaterDate, update it
        if (existingDeliveryDate.isBefore(greaterDate)) {
          element.deliveryDate = greaterDate.format("YYYY-MM-DD");
          element.deliveryTime = greaterDate.format("HH:mm");
        }

        element.maxDeliveryDate = maxOrderDuration.format("YYYY-MM-DD");
        element.maxDeliveryTime = maxOrderDuration.format("HH:mm");

        element.timeSlotAvl = [];
        let deliveryDateStr = moment(element.deliveryDate).format("YYYY-MM-DD");
        let totTime = existingDeliveryDate.hours() * 60 + existingDeliveryDate.minutes();

        if (totTime < orderTimeMinutes) {
          let matchedSlot = false;
          for (const e of this.timeSlot2) {
            let fromSlotTime = Number(e[0].split(":")[0]) * 60 + Number(e[0].split(":")[1]);

            if (orderTimeMinutes < fromSlotTime) {
              let tSlot = `${e[0]}-${e[1]}`;
              element.timeSlotAvl.push(tSlot);

              if (!matchedSlot) {
                element.deliveryTime = tSlot;
                matchedSlot = true;
              }
            }
          }

          if (!matchedSlot) {
            element.deliveryDate = orderDuration.clone().add(1, 'day').format("YYYY-MM-DD");
            element.timeSlotAvl = this.timeSlot2.map(slot => `${slot[0]}-${slot[1]}`);
            element.deliveryTime = element.timeSlotAvl[0];
          }
        } else {
          // Keep existing selected delivery date if it's valid
          element.deliveryDate = deliveryDateStr;
          element.defaultDate = deliveryDateStr;
          element.maxDeliveryDate = maxOrderDuration.format("YYYY-MM-DD");

          let currTotTime = moment().hours() * 60 + moment().minutes();

          this.timeSlot2.forEach((e) => {
            let fromSlotTime = Number(e[0].split(":")[0]) * 60 + Number(e[0].split(":")[1]);

            if (deliveryDateStr === currDate) {
              if (currTotTime <= fromSlotTime) {
                let tSlot = `${e[0]}-${e[1]}`;
                element.timeSlotAvl.push(tSlot);
              }
            } else {
              let tSlot = `${e[0]}-${e[1]}`;
              element.timeSlotAvl.push(tSlot);
            }
          });
        }
      } else {
        // **Existing logic for other products**
        element.deliveryDate = orderDuration.format("YYYY-MM-DD");
        element.defaultDate = orderDuration.format("YYYY-MM-DD");
        element.deliveryTime = orderDuration.format("HH:mm");

        element.maxDeliveryDate = maxOrderDuration.format("YYYY-MM-DD");
        element.maxDeliveryTime = maxOrderDuration.format("HH:mm");

        let totTime = orderDuration.hours() * 60 + orderDuration.minutes();
        element.timeSlotAvl = [];
        let matchedSlot = false;

        for (const e of this.timeSlot2) {
          let fromSlotTime = Number(e[0].split(":")[0]) * 60 + Number(e[0].split(":")[1]);

          if (totTime < fromSlotTime) {
            let tSlot = `${e[0]}-${e[1]}`;
            element.timeSlotAvl.push(tSlot);

            if (!matchedSlot) {
              element.deliveryTime = tSlot;
              matchedSlot = true;
            }
          }
        }

        if (!matchedSlot) {
          element.deliveryDate = orderDuration.clone().add(1, 'day').format("YYYY-MM-DD");
          element.timeSlotAvl = this.timeSlot2.map(slot => `${slot[0]}-${slot[1]}`);
          element.deliveryTime = element.timeSlotAvl[0];
        }
      }

      // Maintain discount rate
      element.discountRate = element.discountRate || 0.0;

      console.log("Final Delivery Date & Time:", element.deliveryDate, ' ', element.deliveryTime);
      console.log("Available Time Slots:", element.timeSlotAvl);
    });



    //Here set Summary data
    this.productOrderSummary.cartProducts = this.cartProducts;
    this.productOrderSummary.totTaxValue = this.totTaxValue;
    this.productOrderSummary.totDiscount = this.totDiscount;
    this.productOrderSummary.gstAmt = this.gstAmt;
    this.productOrderSummary.cessAmt = this.cessAmt;
    this.productOrderSummary.totalPrice = this.grandTotal;
    this.productOrderSummary.pincode = this.pincodeItem;
    this.productOrderSummary.totBasePremiumFlavourPrice = this.totBasePremiumFlavourPrice;
    this.productOrderSummary.totPremiumFlavourPrice = this.totPremiumFlavourPrice;
    this.productOrderSummaryService.setOrderSummary(this.productOrderSummary);

    this.googleId = JSON.parse(this.localStorage.getItem("google_id"));
    this.googleProvider = JSON.parse(this.localStorage.getItem("google_provider"));
    this.fbId = JSON.parse(this.localStorage.getItem("fb_id"));
    this.fbProvider = JSON.parse(this.localStorage.getItem("fb_provider"));

    this.googleDetails = JSON.parse(this.localStorage.getItem("google_loggedIn_details"));
    this.fbDetails = JSON.parse(this.localStorage.getItem("fb_loggedIn_details"));
    // console.log(this.googleDetails)
    if (this.googleDetails != null) {
      let loginDeliveryAddress: LoginCustomerDetails = new LoginCustomerDetails();
      loginDeliveryAddress.firstName = this.googleDetails.firstName;
      loginDeliveryAddress.lastName = this.googleDetails.lastName;
      loginDeliveryAddress.name = this.googleDetails.name;
      loginDeliveryAddress.email = this.googleDetails.email;
      loginDeliveryAddress.phoneNo = this.googleDetails.phoneNo;
      this.gstSaleInvcumBOS.loginDeliveryAddress = loginDeliveryAddress;
      // console.log(this.gstSaleInvcumBOS.loginDeliveryAddress)
    }
    if (this.fbDetails != null) {
      let loginDeliveryAddress: LoginCustomerDetails = new LoginCustomerDetails();
      loginDeliveryAddress.firstName = this.fbDetails.firstName;
      loginDeliveryAddress.lastName = this.fbDetails.lastName;
      loginDeliveryAddress.name = this.fbDetails.name;
      loginDeliveryAddress.email = this.fbDetails.email;
      loginDeliveryAddress.phoneNo = this.fbDetails.phoneNo;
      this.gstSaleInvcumBOS.loginDeliveryAddress = loginDeliveryAddress;
    }

    this.gstSaleInvcumBOS.notes = "Bill From Order";
    this.gstSaleInvcumBOS.invoiceType = "B2C";
    this.gstSaleInvcumBOS.billType = "Web Order";
    this.gstSaleInvcumBOS.status = "Confirmed";

    //Set Product Cart Dto
    var arr = new Array();
    this.cartProducts.forEach((element) => {
      if (!element.freeByProductCode) {
        let productCartDto: ProductCartDto = new ProductCartDto();
        productCartDto.promotionOfferId = element.offerId;
        productCartDto.offerId = element.offerId;
        productCartDto.designForProductCode = element.designForProductCode;
        productCartDto.productCode = element.product_Code;
        productCartDto.productName = element.product_Name;
        productCartDto.qty = element.orderQuantity;
        productCartDto.basePremiumFlavourPrice = element.basePremiumFlavourPrice * productCartDto.qty;
        productCartDto.premiumFlavourPrice = element.premiumFlavourPrice * productCartDto.qty;
        productCartDto.price = element.sale_Price + element.basePremiumFlavourPrice;
        productCartDto.total = productCartDto.qty * productCartDto.price;
        if (element.discountRate === undefined || element.discountRate == null) {
          productCartDto.discPercent = 0.0;
          productCartDto.discAmt = 0.0;
        } else {
          productCartDto.discPercent = element.discountRate;
          productCartDto.discAmt = element.discountAmt;
        }

        productCartDto.baseAmt = productCartDto.total - productCartDto.discAmt;
        productCartDto.netAmt = element.net_Sale_Price + element.premiumFlavourPrice;
        productCartDto.sgstRate = element.tax.taxRate / 2;
        productCartDto.cgstRate = element.tax.taxRate / 2;
        productCartDto.igstRate = 0.0;
        productCartDto.ugstRate = 0.0;
        productCartDto.cessRate = element.cess?.cessRate;

        productCartDto.taxRate = element.tax?.taxRate;
        productCartDto.cartProductType = element.cartProductType;
        productCartDto.color = element.color;
        productCartDto.shape = element.shape;
        productCartDto.composition = element.composition;
        productCartDto.dimension = element.dimension;
        productCartDto.note = element.note;
        productCartDto.productUnit = element.product_Unit;
        productCartDto.productType = element.product_Type;
        productCartDto.maxDiscountPercentage = element.max_Discount_Percentage;
        productCartDto.hsnCode = element.hsn_Code;
        if (element.cartProductType == "Make To Order") {
          productCartDto.subGroupCode = element.subGroupCode;
        } else {
          productCartDto.subGroupCode = element.productSubGroup?.refNo;
        }
        productCartDto.deliveryDate = element.deliveryDate;
        productCartDto.deliveryTime = element.deliveryTime;
        productCartDto.fileNames = element.fileNames;

        arr.push(productCartDto);
      } else {
        let productCartDto: ProductCartDto = new ProductCartDto();
        productCartDto.promotionOfferId = element.offerId;
        productCartDto.offerId = element.offerId;
        productCartDto.freeByProductCode = element.freeByProductCode;
        productCartDto.designForProductCode = element.designForProductCode;
        productCartDto.productCode = element.product_Code;
        productCartDto.productName = element.product_Name;
        productCartDto.cartProductType = element.cartProductType;
        productCartDto.productUnit = element.product_Unit;
        productCartDto.productType = element.product_Type;
        productCartDto.hsnCode = element.hsn_Code;
        productCartDto.subGroupCode = element.productSubGroup?.refNo;
        productCartDto.qty = element.orderQuantity;
        productCartDto.price = element.sale_Price;
        productCartDto.total = productCartDto.qty * productCartDto.price;
        productCartDto.discPercent = 0.0;
        productCartDto.discAmt = 0.0;
        productCartDto.baseAmt = productCartDto.total - productCartDto.discAmt;
        productCartDto.netAmt = element.net_Sale_Price;
        productCartDto.taxRate = element.tax?.taxRate;
        productCartDto.sgstRate = 0.0;
        productCartDto.cgstRate = 0.0;
        productCartDto.igstRate = 0.0;
        productCartDto.ugstRate = 0.0;
        productCartDto.cessRate = element.cess?.cessRate;
        productCartDto.deliveryDate = element.deliveryDate;
        productCartDto.deliveryTime = element.deliveryTime;
        productCartDto.fileNames = element.fileNames;
        productCartDto.rate = element.rate;
        productCartDto.maxDiscountPercentage = element.max_Discount_Percentage;
        productCartDto.netAmountForLocation = element.netAmountForLocation;
        productCartDto.totalAmount = element.subTotal;
        console.log(productCartDto)
        arr.push(productCartDto);
      }
    });
    this.gstSaleInvcumBOS.details = arr;
    this.productOrderSummaryService.setWebOrder(this.gstSaleInvcumBOS);

    console.log(this.cartProducts);
    console.log(this.gstSaleInvcumBOS)

    this.isShowCheckOut = true;
    // this.navRoute.navigate(["/order-summary"]);

    this.googleId = JSON.parse(this.localStorage.getItem("google_id"));
    this.fbId = JSON.parse(this.localStorage.getItem("fb_id"));

    if (this.googleId != null || this.fbId != null) {
      if (this.deliveryType === 'storePickup') {
        this.navRoute.navigate(["/order-summary", "checkout"]);
      } else if (this.deliveryType === 'delivery') {
        this.navRoute.navigate(["/delivery-address"]);
      }

      // this.navRoute.navigate(["/delivery-address"]);
    } else {
      this.navRoute.navigate(["/login", "checkout"]);
    }
  }

  openStorePickupDialogCustom(type) {
    // this.deliveryType = type;
    const dialogRef = this.dialog.open(StorePickupDialogComponent, {
      width: '300px',
      autoFocus: false,
      panelClass: 'store-dialog-container',
      data: { type: type }
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
      if (result) {
        if (result === 'storePickup') {
          this.toastService.showSuccessToast('Store changed successfully');
          this.deliveryType = JSON.parse(this.localStorage.getItem('delivery_type'));
          this.ngOnInit();
        } else if (result === 'delivery') {
          this.toastService.showSuccessToast('Pin changed successfully');
          this.deliveryType = JSON.parse(this.localStorage.getItem('delivery_type'));
          this.ngOnInit();
        }
      }
    });
  }

  openPinChangeDialogCustom() {
    const dialogRef = this.dialog.open(PinDialogComponent, {
      width: '300px',
      panelClass: 'pin-dialog-container',
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
      if (result?.result) {
        this.toastService.showSuccessToast('Pin changed successfully');
        this.deliveryType = JSON.parse(this.localStorage.getItem('delivery_type'));
        this.ngOnInit();
      }
    });
  }
}
