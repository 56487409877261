import { Component, Injectable, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { DialogPosition, MatDialog } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { APIFactoryEndPoints } from "src/app/common/apifactory-end-points";
import { LocalStorageService } from "src/app/common/LocalStorageService";
import { AdvanceSearchDTO } from "src/app/shared/models/advance-search-dto";
import { ProductGroupWithIdAndName } from "src/app/shared/models/productgroupwithidandname";
import { ProductSubGroupDtoWithIdName } from "src/app/shared/models/productsubgroupdtowithidname";
import { PinDialogService } from "src/app/shared/services/pin-dialog.service";
import { ToastService } from "src/app/shared/services/toastr.service";
import { environment } from "src/environments/environment";
import { getWindow } from "ssr-window";
import { Product } from "../../../../shared/models/product";
import { AuthService } from "../../../../shared/services/auth.service";
import { ProductService } from "../../../../shared/services/product.service";
import { PinDialogComponent } from "../pin-dialog/pin-dialog.component";
import { ProductFilterComponent } from "../product-filter/product-filter/product-filter.component";
import { MatrixDto } from "src/app/shared/models/matrix-dto";
import { Router } from "@angular/router";
import { ProductsWithWebOrderOffers } from "src/app/shared/models/productswithweborderoffers";
import { ProductDesignDialogComponent } from "../product-design-dialog/product-design-dialog.component";
import { HttpErrorResponse } from "@angular/common/http";
import { ProductsWithStock } from "src/app/shared/models/products-with-stock";
import { ViewOfferSetUpDto } from "src/app/shared/models/viewoffersetupdto";
import { OfferDetailsDialogComponent } from "../offer-details-dialog/offer-details-dialog.component";
import { BulkDiscountDto } from "src/app/shared/models/bulkdiscountdto";
import { OfferDialogComponent } from "../offer-dialog/offer-dialog.component";
import { StorePickupDialogComponent } from "../store-pickup-dialog/store-pickup-dialog.component";
import { GoogleCustomer } from "src/app/shared/models/googlecustomer";

@Component({
  selector: 'app-search-product-list',
  templateUrl: './search-product-list.component.html',
  styleUrls: ['./search-product-list.component.scss']
})
// @Injectable()
export class SearchProductListComponent implements OnInit {
  productList: Product[] = new Array(1200);
  loading = false;
  webUrl: string;
  brands = ["All", "Apple", "Realme", "Nokia", "Motorolla"];
  selectedBrand: "All";

  createSetUpForm: UntypedFormGroup;
  matrixList: MatrixDto[] = [];
  groupList: ProductGroupWithIdAndName[] = [];
  subGroupList: ProductSubGroupDtoWithIdName[] = [];
  show: boolean = false;
  subGroupShow: boolean = false;
  pincode: string;
  productWithOffers: ProductsWithWebOrderOffers[];

  page = 1; //demo
  pageNo: number = 0;

  selfLife: any;
  disbleBtn: boolean = false;
  advanceSearchData: AdvanceSearchDTO = new AdvanceSearchDTO();
  companyCode: number;
  designProducts: Product[] = [];
  productWithStock: ProductsWithStock[] = [];

  productOffer: Product;
  discountOffer: number;
  bulkDiscountOffer: BulkDiscountDto;
  offerObj: any;

  serverUrl: string;
  currentUrl: string;
  sharingUrl: string = '';

  deliveryType: string;
  googleCustomer: GoogleCustomer;

  //Subscription
  private productSubcription: Subscription;
  private groupSubcription: Subscription;
  private subGroupSubscription: Subscription;
  private productSubscription: Subscription;
  private webOrderBestProductSubcription: Subscription;
  private offerSubscription: Subscription;
  private getDesignSubscription: Subscription;
  private checkAllowStockSubscription: Subscription;
  private stockSubscription: Subscription;
  private productOfferSubscription: Subscription;
  private productDiscountSubscription: Subscription;
  private productBulkDiscountSubscription: Subscription;

  constructor(
    private localStorage: LocalStorageService,
    public dialog: MatDialog,
    public authService: AuthService,
    private productService: ProductService,
    private toastrService: ToastService,
    private apiFactory: APIFactoryEndPoints,
    private navRoute: Router,
  ) {
    this.currentUrl = getWindow().location.hostname;
    this.serverUrl = this.apiFactory.getServerUrl;
    this.sharingUrl = getWindow().location.origin + '/';

    console.log(this.navRoute.getCurrentNavigation().extras.state);
    this.loading = true;
    if (this.navRoute.getCurrentNavigation().extras.state) {
      this.productList = this.navRoute.getCurrentNavigation().extras.state["data"];

      this.show = true;
      this.loading = false;
      console.log(this.productList);
      if (this.productList.length > 0)
        this.getOfferDetails();
    } else {
      this.navRoute.navigate([""]);
    }
  }

  ngOnInit() {
    this.webUrl = environment.cartApiUrl;
    this.companyCode = JSON.parse(this.localStorage.getItem("company_code"));
    this.deliveryType = JSON.parse(this.localStorage.getItem("delivery_type"));
    this.googleCustomer = JSON.parse(this.localStorage.getItem("google_loggedIn_details"));

    // this.openPinDialog();

    // this.getGroupList();
    // this.getWebOrderBestProductSetUpList();
    // this.getMatrixList();
  }

  getOfferDetails() {
    let productId: number[] = this.productList.filter(item => item.type === 'product').map(element => element.product_Code);

    if (productId.length === 0) return;

    let type = this.deliveryType ? (this.deliveryType === 'storePickup' ? 'Pickup' : 'Delivery') : null;

    if (!type) {
      this.getOfferDetailsFromApi(0, productId);
      return;
    }

    this.offerSubscription = this.productService.getOfferAllowFor(this.apiFactory.getOfferAllowFor, this.currentUrl, type).subscribe((data: string) => {
      if (data === 'Not Available') {
        console.log("No offers available.");
        return;
      }

      let locId = data === 'allowForWebsite' ? this.googleCustomer?.locationCode || 0
        : data === 'allowForLocation' ? JSON.parse(this.localStorage.getItem('location_code')) || 0 : 0;

      this.getOfferDetailsFromApi(locId, productId);
    });
  }

  getOfferDetailsFromApi(locId: number, productId: number[]) {
    this.offerSubscription = this.productService.getOfferDetails(this.apiFactory.getOfferDetails, locId, productId, this.currentUrl)
      .subscribe((data: any) => {
        this.productWithOffers = data;

        this.productList.forEach(element => {
          if (element.type === 'product') {
            let offerData = this.productWithOffers.find(offer => offer.productCode === element.product_Code);
            if (offerData) {
              element.offerDetails = offerData.offerDetails;
              element.offers = offerData.offers;
            }
          }
        });
        console.log(this.productList);
      });
  }

  getMatrixList() {
    let matrixId: number = JSON.parse(this.localStorage.getItem("matrix_code"));
    let groupId: number = JSON.parse(this.localStorage.getItem("prod_group_code"));

    if (groupId) {
      this.getGroupByMatrixCode(matrixId);
      this.onGroupSelect(groupId);
    } else if (matrixId) {
      this.onMatrixSelect(matrixId);
    } else {
      this.getWebOrderBestProductSetUpList();
    }

    this.groupSubcription = this.productService.getMatrixList(this.currentUrl).subscribe((data) => {
      this.matrixList = data;
    });
  }

  onMatrixSelect(matrixCode: number) {
    this.localStorage.setItem("matrix_code", JSON.stringify(matrixCode));
    this.localStorage.removeItem("prod_group_code");

    this.loading = true;
    this.productSubscription = this.productService.getProductDetailsByMatrix(this.apiFactory.getProductDetailsByMatrixCode, matrixCode, this.currentUrl)
      .subscribe((data) => {
        this.productList = data;
        this.show = true;
        this.loading = false;
      });

    this.getGroupByMatrixCode(matrixCode);
  }

  getGroupByMatrixCode(matrixCode: number) {
    this.subGroupSubscription = this.productService.getGroupListByMatrix(this.apiFactory.getGroupDetailsByMatrix, this.currentUrl, matrixCode)
      .subscribe((data) => {
        this.groupList = data;
        this.subGroupShow = true;
      });
  }

  getGroupList() {
    this.selfLife = this.localStorage.getItem("min_selflife");
    console.log(this.selfLife);
    let groupId: number = JSON.parse(this.localStorage.getItem("prod_group_code"));
    let subgroupId: number = JSON.parse(this.localStorage.getItem("prod_subgroup_code"));

    if (subgroupId != undefined) {
      this.getSubGroupByGroupCode(groupId);
      this.onSubGroupSelect(subgroupId);
    } else if (groupId != undefined) {
      this.onGroupSelect(groupId);
    } else {
      this.getWebOrderBestProductSetUpList();
    }

    this.groupSubcription = this.productService.getGroupList(this.currentUrl).subscribe((data) => {
      this.groupList = data;
    });
  }

  onGroupSelect(prodGroupId: number) {
    this.localStorage.setItem("prod_group_code", JSON.stringify(prodGroupId));
    this.localStorage.removeItem("prod_subgroup_code");

    this.loading = true;
    this.productSubscription = this.productService.getProductDetailsBySubGroupCode(this.apiFactory.getProductDetailsByGroupCode, prodGroupId)
      .subscribe((data) => {
        this.productList = data;
        this.show = true;
        this.loading = false;
      });

    // this.getSubGroupByGroupCode(prodGroupId);
  }

  getSubGroupByGroupCode(prodGroupId: number) {
    this.subGroupSubscription = this.productService.getSubGroupDetailsByGroupCode(this.apiFactory.getSubGroupDetailsByGroupCode, prodGroupId)
      .subscribe((data) => {
        this.subGroupList = data;
        this.subGroupShow = true;
      });
  }

  onSubGroupSelect(prodSubGroupId: number) {
    this.localStorage.setItem("prod_subgroup_code", JSON.stringify(prodSubGroupId));

    this.loading = true;
    this.productSubscription = this.productService
      .getProductDetailsBySubGroupCode(
        this.apiFactory.getProductDetailsBySubGroupCode,
        prodSubGroupId
      )
      .subscribe((data) => {
        this.productList = data;
        this.show = true;
        this.loading = false;
      });
  }

  getAllProducts() {
    this.loading = true;
    this.pageNo = this.pageNo + 1;
    this.productSubcription = this.productService.getProducts(this.pageNo).subscribe((data) => {
      this.productList = data;
      this.loading = false;
    });
  }

  getWebOrderBestProductSetUpList() {
    this.webOrderBestProductSubcription = this.productService.getWebOrderBestProductSetup(this.apiFactory.getBestProducts, this.currentUrl)
      .subscribe((data) => {
        this.productList = data;
        this.show = true;
        this.loading = false;
        console.log(this.productList);
      });
  }

  specialProductCodes: number[] = [901, 902, 743, 744, 746, 747, 745, 742];
  addFavourite(product: Product) {
    if (this.specialProductCodes.includes(Number(product.product_Code))) {
      this.toastrService.showWarningToast('Coming soon.');
      return;
    }
    const a: Product[] = JSON.parse(this.localStorage.getItem("avf_item")) || [];
    if (product.type = 'product')
      product.cartProductType = "Product";
    else
      product.cartProductType = "Make To Orde";

    this.productService.addFavouriteProduct(product);
  }

  addToCart(product: Product, goToCartPage: boolean) {
    console.log(product);
    // if (!this.specialProductCodes.includes(Number(product.product_Code))) {
    //   this.toastrService.showWarningToast('We are starting with Teachers Day Special cake order.');
    //   return;
    // }
    this.openDeliveryTypeDialog(product, goToCartPage);
  }

  openDeliveryTypeDialog(product: Product, goToCartPage: boolean) {
    let deliveryType = JSON.parse(this.localStorage.getItem('delivery_type'));
    let locId = JSON.parse(this.localStorage.getItem('location_code'));
    console.log('Delivery Type:', deliveryType, 'Location ID:', locId);
    if (!deliveryType || !locId) {
      const dialogRef = this.dialog.open(StorePickupDialogComponent, {
        width: '300px',
        autoFocus: false,
        panelClass: 'store-dialog-container'
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
        if (result) {
          if (result === 'storePickup') {
            this.setStorePickup(product, goToCartPage);
          } else if (result === 'delivery') {
            this.openPinDialog(product, goToCartPage);
          }
        }
      });
    } else {
      if (deliveryType === 'storePickup') {
        this.setStorePickup(product, goToCartPage);
      } else if (deliveryType === 'delivery') {
        this.openPinDialog(product, goToCartPage);
      }
    }
  }

  setStorePickup(product: Product, goToCartPage: boolean) {
    let selfLife = JSON.parse(this.localStorage.getItem('min_selflife'));
    if (product.shelf_Life_In_Hours >= selfLife) {
      product.cartProductType = 'Product';
      this.getDesignBySubGroupAndUrl(product, goToCartPage);
    } else {
      this.toastrService.showErrorToast("Cannot add to cart, shelf life is under " + selfLife + " hours.");
    }
  }

  openPinDialog(product: Product, goToCartPage: boolean) {
    this.pincode = this.localStorage.getItem("pincode_item");
    console.log(product);

    if (this.pincode == null) {
      const dialogRef = this.dialog.open(PinDialogComponent, {
        width: "300px",
        // data: product,
        panelClass: "pin-dialog-container",
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result?.result == true) {
          // this.getGroupList();
          let selfLife = JSON.parse(this.localStorage.getItem("min_selflife"));
          if (product.shelf_Life_In_Hours >= selfLife) {
            product.cartProductType = "Product";
            // this.productService.addToCart(product);
            // this.getDesignBySubGroupAndUrl(product);
            this.getProductWithStock(product, goToCartPage);
          } else {
            this.toastrService.showErrorToast("Cannot add to cart, shelf life is under " + selfLife + " hours.");
          }
        }
      });
    } else {
      // this.getGroupList();
      let selfLife = JSON.parse(this.localStorage.getItem("min_selflife"));
      if (product.shelf_Life_In_Hours >= selfLife) {
        product.cartProductType = "Product";
        // this.productService.addToCart(product);
        // this.getDesignBySubGroupAndUrl(product);
        this.getProductWithStock(product, goToCartPage);
      } else {
        this.toastrService.showErrorToast("Cannot add to cart, shelf life is under " + selfLife + " hours.");
      }
    }
  }

  getProductWithStock(product: Product, goToCartPage: boolean) {
    let locId = JSON.parse(this.localStorage.getItem("location_code"));
    if (locId) {
      this.checkAllowStockSubscription = this.productService.checkAllowStockByLocAndUrl(this.apiFactory.checkAllowStockByLocAndUrl, locId, this.currentUrl).subscribe((data: any) => {
        if (data) {
          if (product.product_Code) {
            this.stockSubscription = this.productService.getStockDetails(this.apiFactory.getStockDetails, [product.product_Code], locId).subscribe((data: any) => {
              this.productWithStock = data;

              var arr = this.productWithStock.filter((p) => p.product_Code == product.product_Code);
              if (arr.length != 0) {
                product.systemStock = arr[0].systemStock;
              }
              if (product.cartProductType == 'Product' && product.minimum_Order_Quantity > product.systemStock) {
                this.toastrService.showErrorToast(product.product_Name + " is out of stock, stock available " + product.systemStock);
                return;
              }
              this.getDesignBySubGroupAndUrl(product, goToCartPage);
            },
              (error: HttpErrorResponse) => {
                this.toastrService.showErrorToast("Something wrong..");
              });
          } else {

          }
        } else {
          this.getDesignBySubGroupAndUrl(product, goToCartPage);
        }
      },
        (error: HttpErrorResponse) => {
          this.toastrService.showErrorToast("Something wrong..");
        })
    } else {
      this.toastrService.showErrorToast("Location can't place order");
    }
  }

  getDesignBySubGroupAndUrl(product: Product, goToCartPage: boolean) {
    const cartProducts: Product[] = JSON.parse(this.localStorage.getItem("avct_item")) || [];
    if (cartProducts.some((temp) => temp.product_Code == product.product_Code)) {
      if (goToCartPage) {
        this.productService.navigateToCart(product);
        return;
      }
      else {
        this.toastrService.showErrorToast(product.product_Name + ' is already added to cart');
        return;
      }
    }
    this.getDesignSubscription = this.productService.getWebOrderDesigns(this.apiFactory.getWebOrderDesignBySubgroupAndUrl, product.productSubGroup.refNo, this.currentUrl).subscribe((data) => {
      console.log(data);
      if (data.length > 0) {
        this.designProducts = data;
        const cartProducts: Product[] = JSON.parse(this.localStorage.getItem("avct_item")) || [];
        let designIndex = cartProducts.findIndex(e => e.designForProductCode === product.product_Code);
        if (designIndex >= 0) {
          this.designProducts.map(e => e.is_Default = false);
          this.designProducts.filter(item => item.product_Code == cartProducts[designIndex].product_Code).map(e => e.is_Default = true);
        }
        this.openDesignDialog(product, this.designProducts, goToCartPage);
      } else {
        if (product.offerDetails?.length > 0)
          this.openOfferDialog(product, null, goToCartPage)
        else {
          this.productService.addToCart(product);

          if (goToCartPage) {
            //Added to redirect to cart
            this.productService.navigateToCart(product);
          }
        }
        // this.toastrService.showErrorToast('No design available');
      }
    });
  }

  openDesignDialog(product: Product, designProducts: Product[], goToCartPage: boolean) {
    document.body.classList.add('dialog-open');
    const dialogRef = this.dialog.open(ProductDesignDialogComponent, {
      width: "430px",
      height: "410px",
      maxWidth: "90vw",
      data: designProducts,
      panelClass: "custom-dialog-container",
    });

    dialogRef.afterClosed().subscribe((result) => {
      document.body.classList.remove('dialog-open');
      if (result) {
        console.log(result.data)
        console.log(result.noDesign)
        if (result?.data) {
          let design: Product = result?.data;
          design.designForProductCode = product.product_Code;
          design.cartProductType = "Product";
          if (product.offerDetails?.length > 0)
            this.openOfferDialog(product, design, goToCartPage)
          else {
            this.productService.addToCartDesign(product, design);

            if (goToCartPage) {
              //Added to redirect to cart
              this.productService.navigateToCart(product);
            }
          }
        } else if (result?.noDesign) {
          if (product.offerDetails?.length > 0)
            this.openOfferDialog(product, null, goToCartPage)
          else {
            this.productService.addToCart(product);

            if (goToCartPage) {
              //Added to redirect to cart
              this.productService.navigateToCart(product);
            }
          }
        }
      }
    });
  }

  openOfferDialog(product: Product, design: Product, goToCartPage: boolean) {
    console.log(product)
    const cartProducts: Product[] = JSON.parse(this.localStorage.getItem("avct_item")) || [];
    if (cartProducts.some((temp) => temp.product_Code == product.product_Code)) {
      if (goToCartPage) {
        this.productService.navigateToCart(product);
        return;
      }
      else {
        this.toastrService.showErrorToast(product.product_Name + ' is already added to cart');
        return;
      }
    }
    let offers: ViewOfferSetUpDto[] = product.offerDetails;
    let appliedOfferId: string = product.offerId ? product.offerId : product.offerDetails[0].offerId;
    const dialogRef = this.dialog.open(OfferDialogComponent, {
      width: "500px",
      height: "300px",
      data: { offers, appliedOfferId },
    });

    dialogRef.afterClosed().subscribe((offerObj) => {
      console.log(offerObj);
      if (offerObj) {
        this.offerObj = offerObj;
        //conditions
        var orderQuantity;
        var freeByProductCode;
        var cartProductType;

        if (product) {
          product.orderQuantity = offerObj.minQty;
          orderQuantity = product.orderQuantity;
          freeByProductCode = product.product_Code;
          cartProductType = product.cartProductType;
          if (product.cartProductType == "Product") {
            product.basePremiumFlavourPrice = 0;
            product.premiumFlavourPrice = 0;
          }
        }
        console.log(product);

        if (offerObj.schemeType == "Buy 1 get 1 Free" || offerObj.schemeType == "Buy X get Y Free") {
          this.productOfferSubscription = this.productService.getProductOfferDetails(this.apiFactory.productOfferDetails, offerObj.offerId, orderQuantity)
            .subscribe(
              (data) => {
                // offer processing
                this.productOffer = data;
                //set order qty as product QTY IN CART
                this.productOffer.product_Name = this.productOffer.product_Name.concat("(").concat(offerObj.schemeType).concat(")");
                this.productOffer.orderQuantity = orderQuantity;
                this.productOffer.net_Sale_Price = offerObj.productPriceForCustomer;
                this.productOffer.sale_Price = offerObj.productPriceForCustomer;
                this.productOffer.discountAmt = 0;
                this.productOffer.discountRate = 0;
                this.productOffer.freeProductNetSalePrice = offerObj.netSalePrice;
                this.productOffer.freeByProductCode = freeByProductCode;
                this.productOffer.cartProductType = cartProductType;
                this.productOffer.product_Type = this.productOffer.product_Type;
                this.productOffer.product_Unit = this.productOffer.product_Unit;
                this.productOffer.food_Type = this.productOffer.food_Type;
                this.productOffer.hsn_Code = this.productOffer.hsn_Code;

                // main product tax and net amount calculations
                product.discountAmt = 0;
                product.discountRate = 0;
                var taxableAmt = product.sale_Price - (product.sale_Price * 0) / 100;
                taxableAmt = taxableAmt + product.basePremiumFlavourPrice;
                var cess = product.cess.cessRate;
                var cessAmt = (cess / 100) * taxableAmt;
                var cgst = (product.tax.taxRate / 2 / 100) * taxableAmt;
                var sgst = (product.tax.taxRate / 2 / 100) * taxableAmt;
                var tot = cgst + sgst;
                product.promotionOfferId = offerObj.offerId;
                product.offerId = offerObj.offerId;
                product.taxAmount = tot; //Tax Amount
                product.cgstAmt = cgst;
                product.sgstAmt = sgst;
                product.cessAmount = cessAmt;
                product.net_Sale_Price = taxableAmt + tot + cessAmt;
                console.log(product);
                this.productService.addToCartWithOffer(product, this.productOffer, design);

                if (goToCartPage) {
                  //Added to redirect to cart
                  this.productService.navigateToCart(product);
                }
              },
              (err) => {
                if (err.error) {
                  this.toastrService.showErrorToast(err.error.message);
                }
              }
            );
        } else {
          if (offerObj.schemeType == "Buy x get x percent off") {
            this.productDiscountSubscription = this.productService.getProductDiscountDetails(this.apiFactory.productDiscountDetails, offerObj.offerId, orderQuantity)
              .subscribe(
                (data) => {
                  this.discountOffer = data;
                  product.discountAmt = (this.discountOffer / 100) * product.sale_Price;
                  product.discountRate = this.discountOffer;
                  var taxableAmt = product.sale_Price - (product.sale_Price * this.discountOffer) / 100;
                  taxableAmt = taxableAmt + product.basePremiumFlavourPrice;
                  var cess = product.cess.cessRate;
                  var cessAmt = (cess / 100) * taxableAmt;
                  var cgst = (product.tax.taxRate / 2 / 100) * taxableAmt;
                  var sgst = (product.tax.taxRate / 2 / 100) * taxableAmt;
                  var tot = cgst + sgst;
                  product.promotionOfferId = offerObj.offerId;
                  product.offerId = offerObj.offerId;
                  product.taxAmount = tot; //Tax Amount
                  product.cgstAmt = cgst;
                  product.sgstAmt = sgst;
                  product.cessAmount = cessAmt;
                  product.net_Sale_Price = taxableAmt + tot + cessAmt;
                  this.productService.addToCartWithOffer(product, null, design);

                  if (goToCartPage) {
                    //Added to redirect to cart
                    this.productService.navigateToCart(product);
                  }
                },
                (err) => {
                  if (err.error) {
                    this.toastrService.showErrorToast(err.error.message);
                  }
                }
              );
          } else {
            this.productBulkDiscountSubscription = this.productService.getProductBulkDiscountDetails(this.apiFactory.productBulkOfferDetails, offerObj.offerId)
              .subscribe((data) => {
                this.bulkDiscountOffer = data;
                product.discountAmt = (this.bulkDiscountOffer.discount / 100) * product.sale_Price;
                product.discountRate = this.bulkDiscountOffer.discount;
                var taxableAmt = product.sale_Price - (product.sale_Price * this.bulkDiscountOffer.discount) / 100;
                taxableAmt = taxableAmt + product.basePremiumFlavourPrice;
                var cess = product.cess.cessRate;
                var cessAmt = (cess / 100) * taxableAmt;
                var cgst = (product.tax.taxRate / 2 / 100) * taxableAmt;
                var sgst = (product.tax.taxRate / 2 / 100) * taxableAmt;
                var tot = cgst + sgst;
                product.promotionOfferId = offerObj.offerId;
                product.offerId = offerObj.offerId;
                product.taxAmount = tot; //Tax Amount
                product.cgstAmt = cgst;
                product.sgstAmt = sgst;
                product.cessAmount = cessAmt;
                product.net_Sale_Price = taxableAmt + tot + cessAmt;
                this.productService.addToCartWithOffer(product, null, design);

                if (goToCartPage) {
                  //Added to redirect to cart
                  this.productService.navigateToCart(product);
                }
              });
          }
        }
      } else {
        // if (design)
        //   this.productService.addToCartDesign(product, design);
        // else
        //   this.productService.addToCart(product);
      }
    });
  }

  // openPinDialog() {
  //   this.pincode = this.localStorage.getItem("pincode_item");

  //   if (this.pincode == null) {
  //     const dialogRef = this.dialog.open(PinDialogComponent, {
  //       width: "300px",
  //       // data: product,
  //       panelClass: "pin-dialog-container",
  //     });

  //     dialogRef.afterClosed().subscribe((result) => {
  //       if (result?.result == true) {
  //         this.getGroupList();
  //       }
  //     });
  //   } else {
  //     this.getGroupList();
  //   }
  // }
  openFilterDialog(): void {
    this.advanceSearchData.module = "WebProduct";

    const dialogPosition: DialogPosition = {
      top: "250px",
      right: "0px",
    };
    const dialogRef = this.dialog.open(ProductFilterComponent, {
      width: "220px",
      position: dialogPosition,
      data: { advSearchInput: this.advanceSearchData, disbleBtn: this.disbleBtn, },
      panelClass: 'custom-container'
    });

    dialogRef.afterClosed().subscribe((data) => {
      console.log(data);
      if (data) {
        // console.log(data)
        let list = data.resultProductList;
        if (list.length >= 0) {
          this.productList = list;
        }
        this.disbleBtn = data.disbleBtn;

        this.advanceSearchData = data.advSearchInput;
      }
    });
  }

  viewOfferDetail(offers: ViewOfferSetUpDto[]) {
    console.log(offers)
    let dialogRef = this.dialog.open(OfferDetailsDialogComponent, {
      width: '550px',
      minHeight: '18%',
      maxWidth: '90%',
      maxHeight: '70%',
      autoFocus: false,
      data: { offerData: offers }
    });

    dialogRef.afterClosed().subscribe(res => {

    })
  }

  onShareClick(product: Product) {
    var url = 'https://web.whatsapp.com/send'
    var text = getWindow().location.origin + '/' + product.type + '/' + product.product_Code;
    console.log(text)
    window.open(url + '?text=' + text)
  }
}

